var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card-text', [_c('h3', [_vm._v("Other net income")]), _c('div', {
    staticClass: "mb-5"
  }, [_c('v-simple-table', {
    staticClass: "mt-5",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_vm.isOtherEmployeeIncomeCopy ? _c('tbody', _vm._l(_vm.otherIncomes, function (otherIncome, idx) {
          return _c('tr', {
            key: idx
          }, [_c('td', [_c('v-text-field', {
            staticClass: "pa-5",
            attrs: {
              "dense": "",
              "type": "text",
              "placeholder": "Description",
              "label": "Description",
              "disabled": _vm.FIELDS_DISABLED
            },
            model: {
              value: otherIncome.description,
              callback: function callback($$v) {
                _vm.$set(otherIncome, "description", $$v);
              },
              expression: "otherIncome.description"
            }
          })], 1), _c('td', [_c('v-text-field', {
            attrs: {
              "dense": "",
              "type": "text",
              "placeholder": "Income, $",
              "label": "Income, $",
              "disabled": _vm.FIELDS_DISABLED
            },
            on: {
              "focus": function focus($event) {
                var _vm$otherIncomesCopy, _vm$otherIncomesCopy$;

                return _vm.inputNumberType($event, (_vm$otherIncomesCopy = _vm.otherIncomesCopy) === null || _vm$otherIncomesCopy === void 0 ? void 0 : (_vm$otherIncomesCopy$ = _vm$otherIncomesCopy[idx]) === null || _vm$otherIncomesCopy$ === void 0 ? void 0 : _vm$otherIncomesCopy$.income, idx);
              },
              "blur": function blur($event) {
                var _vm$otherIncomesCopy2, _vm$otherIncomesCopy3;

                return _vm.inputTextType($event, (_vm$otherIncomesCopy2 = _vm.otherIncomesCopy) === null || _vm$otherIncomesCopy2 === void 0 ? void 0 : (_vm$otherIncomesCopy3 = _vm$otherIncomesCopy2[idx]) === null || _vm$otherIncomesCopy3 === void 0 ? void 0 : _vm$otherIncomesCopy3.income, idx);
              }
            },
            model: {
              value: _vm.otherIncomesCopy[idx].income,
              callback: function callback($$v) {
                _vm.$set(_vm.otherIncomesCopy[idx], "income", $$v);
              },
              expression: "otherIncomesCopy[idx].income"
            }
          })], 1), _c('td', [_c('v-select', {
            attrs: {
              "dense": "",
              "items": _vm.incomeFreqOptions,
              "label": "Income Frequency",
              "disabled": _vm.FIELDS_DISABLED
            },
            model: {
              value: otherIncome.income_freq,
              callback: function callback($$v) {
                _vm.$set(otherIncome, "income_freq", $$v);
              },
              expression: "otherIncome.income_freq"
            }
          })], 1), _c('td', [_c('v-icon', {
            attrs: {
              "disabled": _vm.FIELDS_DISABLED
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.removeOtherIncome(idx);
              }
            }
          }, [_vm._v(" mdi-delete ")])], 1)]);
        }), 0) : _vm._e()];
      },
      proxy: true
    }])
  }), _c('v-btn', {
    attrs: {
      "color": "primary",
      "disabled": _vm.FIELDS_DISABLED
    },
    on: {
      "click": _vm.addOtherIncome
    }
  }, [_vm._v(" Add new ")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }