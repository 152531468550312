<template>
  <v-card-text>
    <h3>Employee income</h3>

    <div class="mb-5">
      <v-simple-table class="mt-5">
          <template #default>
            <tbody v-if="isincomesCopy">
            <tr
              v-for="(employeeIncome, idx) in employeeIncomes"
              :key="idx"
            >
              <td>
                <v-combobox
                  v-model="employeeIncome.ref"
                  :items="referenceOptions"
                  return-object
                  item-text="name"
                  label="Reference"
                  class="pa-5"
                  dense
                  :disabled="FIELDS_DISABLED"
                />
              </td>

              <td>
                <v-text-field
                  dense
                  type="text"
                  v-model="incomesCopy[idx].income"
                  @focus="inputNumberType($event, incomesCopy?.[idx]?.income, idx)"
                  @blur="inputTextType($event, incomesCopy?.[idx]?.income, idx)"
                  placeholder="Income, $"
                  label="Income, $"
                  :disabled="FIELDS_DISABLED"
                />
              </td>

              <td>
                <v-select
                  dense
                  v-model="employeeIncome.income_freq"
                  :items="incomeFreqOptions"
                  label="Income Frequency"
                  :disabled="FIELDS_DISABLED"
                />
              </td>

              <td>
                <v-icon @click.prevent="removeEmployeeIncome(idx)" :disabled="FIELDS_DISABLED">
                  mdi-delete
                </v-icon>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      <v-btn color="primary" @click="addEmployeeIncome" :disabled="FIELDS_DISABLED">
        Add new
      </v-btn>
    </div>
  </v-card-text>
</template>

<script>
import { mapGetters } from 'vuex';

import { clone } from '@/utils/object';

import FeedOptionsMixin from '@/mixins/FeedOptionsMixin';

export default {
  name: 'EmployeeIncomes',

  mixins: [FeedOptionsMixin],

  emits: ['onUpdateEmployeeIncomes'],

  props: {
    existedEmployeeIncomes: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      isSectionVisible: false,

      typeOptions: ['Rent', 'Boarder'],

      incomeFreqOptions: ['Year', 'Month', 'Week'],

      initialEmployeeIncomes: [],

      employeeIncomes: [],

      incomesCopy: [],
    };
  },

  computed: {
    ...mapGetters(['FIELDS_DISABLED']),

    isincomesCopy() {
      return this.incomesCopy;
    },
  },

  async created() {
    this.initialemployeeIncomes = clone(this.existedEmployeeIncomes);
    this.employeeIncomes = clone(this.existedEmployeeIncomes);
    this.incomesCopy = clone(this.employeeIncomes);
    this.incomesCopy.forEach((item, index) => {
      this.incomesCopy[index].income = this.$options.filters.integerFormatter(item.income);
    });
  },

  watch: {
    employeeIncomes: {
      handler() {
        this.$emit('onUpdateEmployeeIncomes', this.employeeIncomes);
      },

      deep: true,
    },
  },

  methods: {
    addEmployeeIncome() {
      const employeeIncomesData = {
        id: (new Date()).getTime(),
        ref: '',
        income: '',
        income_freq: '',
      };

      this.employeeIncomes.push(employeeIncomesData);
      this.incomesCopy = clone(this.employeeIncomes);
      this.incomesCopy.forEach((item, index) => {
        this.incomesCopy[index].income = this.$options.filters.integerFormatter(item.income);
      });
    },

    removeEmployeeIncome(idxEmployeeIncome) {
      this.employeeIncomes.splice(idxEmployeeIncome, 1);
      this.incomesCopy = clone(this.employeeIncomes);
      this.incomesCopy.forEach((item, index) => {
        this.incomesCopy[index].income = this.$options.filters.integerFormatter(item.income);
      });
    },

    inputTextType(event, value, index) {
      const target = event?.target;
      target.type = 'text';
      this.incomesCopy[index].income = this.$options.filters.integerFormatter(value);
      this.employeeIncomes[index].income = this.$options.filters.textFormatter(value);
    },

    inputNumberType(event, value, index) {
      const target = event?.target;
      target.type = 'number';
      this.incomesCopy[index].income = this.$options.filters.textFormatter(value);
    },
  },
};
</script>
