var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card-text', [_c('h3', [_vm._v("Rental income")]), _c('div', {
    staticClass: "mb-5"
  }, [_c('v-simple-table', {
    staticClass: "mt-5",
    scopedSlots: _vm._u([_vm.rentalIncomes ? {
      key: "default",
      fn: function fn() {
        return [_c('tbody', _vm._l(_vm.rentalIncomes, function (rentalIncome, idx) {
          return _c('RentalIncomesRow', {
            key: idx,
            attrs: {
              "rentalIncome": rentalIncome,
              "lendingPurpose": _vm.lending_purpose,
              "userRole": _vm.userRole
            },
            on: {
              "removeRentalIncome": function removeRentalIncome($event) {
                return _vm.removeRentalIncome(idx);
              }
            }
          });
        }), 1)];
      },
      proxy: true
    } : null], null, true)
  }), _c('v-btn', {
    attrs: {
      "color": "primary",
      "disabled": _vm.FIELDS_DISABLED
    },
    on: {
      "click": _vm.addRentalIncome
    }
  }, [_vm._v(" Add new ")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }