<template>
  <BaseSection
    :id="section.key"
    :is-visible="isSectionVisible"
    :is-modified="isDataChanged"
    :section="section"
    @onSectionSaveClick="save"
  >
    <template #section>
        <EmployeeIncomes
          v-if="incomes?.employee"
          :key="`employee-${isDataChanged}`"
          :existedEmployeeIncomes="incomes?.employee"
          @onUpdateEmployeeIncomes="updateEmployeeIncomes"
        />

        <RentalIncomes
          v-if="incomes?.rental"
          :key="`rental-${isDataChanged}`"
          :existedRentalIncomes="incomes?.rental"
          @onUpdateRentalIncomes="updateRentalIncomes"
          :userRole="userRole"
        />

        <OtherIncomes
          v-if="incomes?.other"
          :key="`other-${isDataChanged}`"
          :existedOtherIncomes="incomes?.other"
          @onUpdateOtherIncomes="updateOtherIncomes"
        />
    </template>
  </BaseSection>
</template>

<script>
import Vue from 'vue';

import { clone } from '@/utils/object';

import SectionMixin from '@/mixins/SectionMixin';

import BaseSection from '@/components/ApplicationSections/BaseSection.vue';
import RentalIncomes from '@/components/ApplicationSections/Components/RentalIncomes.vue';
import EmployeeIncomes from '@/components/ApplicationSections/Components/EmployeeIncomes.vue';
import OtherIncomes from '@/components/ApplicationSections/Components/OtherIncomes.vue';

export default {
  name: 'IncomesSection',

  components: {
    BaseSection,
    RentalIncomes,
    EmployeeIncomes,
    OtherIncomes,
  },

  mixins: [SectionMixin],

  props: {
    userRole: {
      type: String,
      default: '',
    },

    existedData: {
      type: Object,
      default() {
        return {};
      },
    },

    section: {
      type: Object,
    },
  },

  data() {
    return {
      isSectionVisible: false,

      initialIncomes: {},
      incomes: {
        other: [],
        employee: [],
        rental: [],
      },
    };
  },

  async created() {
    await this.checkIsSectionVisible();

    this.initialIncomes = await clone(this.existedData);
    this.incomes = await clone(this.existedData);

    this.$emit('componentReady');
  },

  methods: {
    updateRentalIncomes(rentalIncomes) {
      Vue.set(this.incomes, 'rental', rentalIncomes);
    },

    updateEmployeeIncomes(employeeIncomes) {
      Vue.set(this.incomes, 'employee', employeeIncomes);
    },

    updateOtherIncomes(otherIncomes) {
      Vue.set(this.incomes, 'other', otherIncomes);
    },
  },
};
</script>
