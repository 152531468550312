<template>
  <v-card-text>
    <h3>Rental income</h3>
    <div class="mb-5">
      <v-simple-table class="mt-5">
          <template #default v-if="rentalIncomes">
            <tbody>
              <RentalIncomesRow
                v-for="(rentalIncome, idx) in rentalIncomes"
                :key="idx"
                :rentalIncome="rentalIncome"
                :lendingPurpose="lending_purpose"
                @removeRentalIncome="removeRentalIncome(idx)"
                :userRole="userRole"
              >
              </RentalIncomesRow>
            </tbody>
          </template>
        </v-simple-table>
      <v-btn color="primary" @click="addRentalIncome" :disabled="FIELDS_DISABLED">
        Add new
      </v-btn>
    </div>
  </v-card-text>
</template>

<script>
import { mapGetters } from 'vuex';

import { clone } from '@/utils/object';

import RentalIncomesRow from '@/components/ApplicationSections/Components/RentalIncomesRow.vue';

export default {
  name: 'RentalIncomes',

  emits: ['onUpdateRentalIncomes'],

  components: {
    RentalIncomesRow,
  },

  props: {
    userRole: {
      type: String,
      default: '',
    },

    existedRentalIncomes: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      isSectionVisible: false,

      typeOptions: ['Rent', 'Boarder'],

      incomeFreqOptions: ['Year', 'Month', 'Week'],

      initialRentalIncomes: [],

      rentalIncomes: [],
    };
  },

  computed: {
    ...mapGetters(['APPLICATION', 'FIELDS_DISABLED']),

    lending_purpose() {
      const lendingPurpose = this.APPLICATION.data.funding_details.lending_purpose;
      const firstLendingPurpose = lendingPurpose.find((item) => item === 'New Purchase');

      if (firstLendingPurpose) {
        return true;
      }

      return false;
    },
  },

  mounted() {
    this.initialRentalIncomes = clone(this.existedRentalIncomes);
    this.rentalIncomes = clone(this.existedRentalIncomes);
  },

  watch: {
    rentalIncomes: {
      handler() {
        this.$emit('onUpdateRentalIncomes', this.rentalIncomes);
      },

      deep: true,
    },
  },

  methods: {
    addRentalIncome() {
      const rentalIncomesData = {
        id: (new Date()).getTime(),
        address: '',
        type: '',
        income: '',
        income_freq: '',
        discount: '',
        lending_purpose: '',
      };

      this.rentalIncomes.push(rentalIncomesData);
    },

    removeRentalIncome(idxRentalIncome) {
      this.rentalIncomes.splice(idxRentalIncome, 1);
    },
  },
};
</script>
