<template>
  <tr>
    <td>
      <v-autocomplete
        no-filter
        dense
        autocomplete="nofill"
        v-model="rentalIncome.address"
        :items="addressOptions"
        :search-input.sync="rentalIncomeAddress"
        item-text="address"
        label="Address"
        hide-no-data
        :disabled="FIELDS_DISABLED"
      />
    </td>

    <td>
      <v-combobox
        v-model="rentalIncome.type"
        :items="typeOptions"
        label="Type"
        class="pa-5"
        dense
        :disabled="FIELDS_DISABLED"
      />
    </td>

    <td>
      <v-text-field
        dense
        type="text"
        v-model="rentalIncomeIncome"
        @focus="inputNumberType"
        @blur="inputTextType"
        placeholder="Income, $"
        label="Income, $"
        :disabled="FIELDS_DISABLED"
      />
    </td>

    <td>
      <v-select
        dense
        v-model="rentalIncome.income_freq"
        :items="incomeFreqOptions"
        label="Income Frequency"
        :disabled="FIELDS_DISABLED"
      />
    </td>

    <td>
      <v-text-field
        v-if="userRole !== 'client'"
        dense
        type="number"
        v-model="rentalIncomeDiscount"
        @blur="discountValueChange"
        placeholder="Shading, %"
        label="Shading, %"
        :disabled="FIELDS_DISABLED"
      />
    </td>

    <td>
      <v-icon @click.prevent="removeRentalIncome" :disabled="FIELDS_DISABLED">
        mdi-delete
      </v-icon>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex';

import SummaryMixin from '@/mixins/SummaryMixin';

import { getRentalAddressList } from '@/services/addresses';

export default {
  name: 'RentalIncomesRow',

  mixins: [SummaryMixin],

  props: {
    userRole: {
      type: String,
      default: '',
    },

    rentalIncome: {
      type: Object,
    },

    lendingPurpose: {
      type: Boolean,
    },
  },

  data() {
    return {
      rentalIncomeAddress: '',

      rentalIncomeIncome: null,
      rentalIncomeDiscount: null,

      addressOptions: [],

      typeOptions: ['Rent', 'Boarder'],

      incomeFreqOptions: ['Year', 'Month', 'Week'],
    };
  },

  computed: {
    ...mapGetters(['APPLICATION', 'FIELDS_DISABLED']),

    isNewPurchase() {
      const price = this.APPLICATION?.data?.funding_details?.new_purchase[0]?.estimated_price;
      if (price !== '') {
        return true;
      }
      return false;
    },

    availableAddresses() {
      const addressList = [];

      if (this.isNewPurchase) {
        addressList.push('Proposed Home');
      }

      this.APPLICATION.data.real_estate_assets.forEach((item) => {
        if (item.address) {
          addressList.push(item.address);
        }
      });

      return addressList;
    },
  },

  created() {
    this.rentalIncomeIncome = this.$options.filters.integerFormatter(this.rentalIncome.income);
    this.rentalIncomeDiscount = this.rentalIncome.discount;
  },

  watch: {
    rentalIncomeAddress: {
      async handler(searchString) {
        if (searchString) {
          this.addressOptions = await getRentalAddressList(searchString, this.availableAddresses);
        } else {
          this.addressOptions = ['New property', ...this.availableAddresses];
        }
      },
    },

    'rentalIncome.address': {
      handler() {
        this.rentalIncomeAddress = this.rentalIncome.address;
      },

      immediate: true,
    },

    'APPLICATION.data.real_estate_assets': {
      handler(value) {
        if (value) {
          this.addressOptions = [this.rentalIncome.address, ...this.availableAddresses];
        }
      },
    },

    'APPLICATION.data.funding_details.lending_purpose': {
      handler(value) {
        if (value) {
          this.addressOptions = [this.rentalIncome.address, ...this.availableAddresses];
        }
      },
    },
  },

  methods: {
    discountValueChange() {
      this.rentalIncome.discount = this.rentalIncomeDiscount;
    },

    removeRentalIncome() {
      this.$emit('removeRentalIncome');
    },

    inputTextType(event) {
      const target = event?.target;
      target.type = 'text';
      this.rentalIncomeIncome = this.$options.filters.integerFormatter(this.rentalIncomeIncome);
      this.rentalIncome.income = this.$options.filters.textFormatter(this.rentalIncomeIncome);
    },

    inputNumberType(event) {
      const target = event?.target;
      target.type = 'number';
      this.rentalIncomeIncome = this.$options.filters.textFormatter(this.rentalIncomeIncome);
    },
  },
};
</script>
