var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseSection', {
    attrs: {
      "id": _vm.section.key,
      "is-visible": _vm.isSectionVisible,
      "is-modified": _vm.isDataChanged,
      "section": _vm.section
    },
    on: {
      "onSectionSaveClick": _vm.save
    },
    scopedSlots: _vm._u([{
      key: "section",
      fn: function fn() {
        var _vm$incomes, _vm$incomes2, _vm$incomes3, _vm$incomes4, _vm$incomes5, _vm$incomes6;

        return [(_vm$incomes = _vm.incomes) !== null && _vm$incomes !== void 0 && _vm$incomes.employee ? _c('EmployeeIncomes', {
          key: "employee-".concat(_vm.isDataChanged),
          attrs: {
            "existedEmployeeIncomes": (_vm$incomes2 = _vm.incomes) === null || _vm$incomes2 === void 0 ? void 0 : _vm$incomes2.employee
          },
          on: {
            "onUpdateEmployeeIncomes": _vm.updateEmployeeIncomes
          }
        }) : _vm._e(), (_vm$incomes3 = _vm.incomes) !== null && _vm$incomes3 !== void 0 && _vm$incomes3.rental ? _c('RentalIncomes', {
          key: "rental-".concat(_vm.isDataChanged),
          attrs: {
            "existedRentalIncomes": (_vm$incomes4 = _vm.incomes) === null || _vm$incomes4 === void 0 ? void 0 : _vm$incomes4.rental,
            "userRole": _vm.userRole
          },
          on: {
            "onUpdateRentalIncomes": _vm.updateRentalIncomes
          }
        }) : _vm._e(), (_vm$incomes5 = _vm.incomes) !== null && _vm$incomes5 !== void 0 && _vm$incomes5.other ? _c('OtherIncomes', {
          key: "other-".concat(_vm.isDataChanged),
          attrs: {
            "existedOtherIncomes": (_vm$incomes6 = _vm.incomes) === null || _vm$incomes6 === void 0 ? void 0 : _vm$incomes6.other
          },
          on: {
            "onUpdateOtherIncomes": _vm.updateOtherIncomes
          }
        }) : _vm._e()];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }