<template>
  <v-card-text>
    <h3>Other net income</h3>

    <div class="mb-5">
      <v-simple-table class="mt-5">
          <template #default>
            <tbody v-if="isOtherEmployeeIncomeCopy">
              <tr
                v-for="(otherIncome, idx) in otherIncomes"
                :key="idx"
              >
                <td>
                  <v-text-field
                    dense
                    type="text"
                    v-model="otherIncome.description"
                    placeholder="Description"
                    label="Description"
                    class="pa-5"
                    :disabled="FIELDS_DISABLED"
                  />
                </td>

                <td>
                  <v-text-field
                    dense
                    type="text"
                    v-model="otherIncomesCopy[idx].income"
                    @focus="inputNumberType($event, otherIncomesCopy?.[idx]?.income, idx)"
                    @blur="inputTextType($event, otherIncomesCopy?.[idx]?.income, idx)"
                    placeholder="Income, $"
                    label="Income, $"
                    :disabled="FIELDS_DISABLED"
                  />
                </td>

                <td>
                  <v-select
                    dense
                    v-model="otherIncome.income_freq"
                    :items="incomeFreqOptions"
                    label="Income Frequency"
                    :disabled="FIELDS_DISABLED"
                  />
                </td>

                <td>
                  <v-icon @click.prevent="removeOtherIncome(idx)" :disabled="FIELDS_DISABLED">
                    mdi-delete
                  </v-icon>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      <v-btn color="primary" @click="addOtherIncome" :disabled="FIELDS_DISABLED">
        Add new
      </v-btn>
    </div>
  </v-card-text>
</template>

<script>
import { mapGetters } from 'vuex';

import { clone } from '@/utils/object';

export default {
  name: 'otherIncomes',

  emits: ['onUpdateOtherIncomes'],

  props: {
    existedOtherIncomes: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      isSectionVisible: false,

      incomeFreqOptions: ['Year', 'Month', 'Week'],

      initialOtherIncomes: [],

      otherIncomes: [],

      otherIncomesCopy: [],

    };
  },

  computed: {
    ...mapGetters(['FIELDS_DISABLED']),

    isOtherEmployeeIncomeCopy() {
      return this.otherIncomesCopy;
    },
  },

  async created() {
    this.initialOtherIncomes = clone(this.existedOtherIncomes);
    this.otherIncomes = clone(this.existedOtherIncomes);
    this.otherIncomesCopy = clone(this.otherIncomes);
    this.otherIncomesCopy.forEach((item, index) => {
      this.otherIncomesCopy[index].income = this.$options.filters.integerFormatter(item.income);
    });
  },

  watch: {
    otherIncomes: {
      handler() {
        this.$emit('onUpdateOtherIncomes', this.otherIncomes);
      },

      deep: true,
    },
  },

  methods: {
    addOtherIncome() {
      const otherIncomesData = {
        id: (new Date()).getTime(),
        description: '',
        income: '',
        income_freq: '',
      };

      this.otherIncomes.push(otherIncomesData);
      this.otherIncomesCopy = clone(this.otherIncomes);
      this.otherIncomesCopy.forEach((item, index) => {
        this.otherIncomesCopy[index].income = this.$options.filters.integerFormatter(item.income);
      });
    },

    removeOtherIncome(idxOtherIncome) {
      this.otherIncomes.splice(idxOtherIncome, 1);
      this.otherIncomesCopy = clone(this.otherIncomes);
      this.otherIncomesCopy.forEach((item, index) => {
        this.otherIncomesCopy[index].income = this.$options.filters.integerFormatter(item.income);
      });
    },

    inputTextType(event, value, index) {
      const target = event?.target;
      target.type = 'text';
      this.otherIncomesCopy[index].income = this.$options.filters.integerFormatter(value);
      this.otherIncomes[index].income = this.$options.filters.textFormatter(value);
    },

    inputNumberType(event, value, index) {
      const target = event?.target;
      target.type = 'number';
      this.otherIncomesCopy[index].income = this.$options.filters.textFormatter(value);
    },
  },
};
</script>
