var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card-text', [_c('h3', [_vm._v("Employee income")]), _c('div', {
    staticClass: "mb-5"
  }, [_c('v-simple-table', {
    staticClass: "mt-5",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_vm.isincomesCopy ? _c('tbody', _vm._l(_vm.employeeIncomes, function (employeeIncome, idx) {
          return _c('tr', {
            key: idx
          }, [_c('td', [_c('v-combobox', {
            staticClass: "pa-5",
            attrs: {
              "items": _vm.referenceOptions,
              "return-object": "",
              "item-text": "name",
              "label": "Reference",
              "dense": "",
              "disabled": _vm.FIELDS_DISABLED
            },
            model: {
              value: employeeIncome.ref,
              callback: function callback($$v) {
                _vm.$set(employeeIncome, "ref", $$v);
              },
              expression: "employeeIncome.ref"
            }
          })], 1), _c('td', [_c('v-text-field', {
            attrs: {
              "dense": "",
              "type": "text",
              "placeholder": "Income, $",
              "label": "Income, $",
              "disabled": _vm.FIELDS_DISABLED
            },
            on: {
              "focus": function focus($event) {
                var _vm$incomesCopy, _vm$incomesCopy$idx;

                return _vm.inputNumberType($event, (_vm$incomesCopy = _vm.incomesCopy) === null || _vm$incomesCopy === void 0 ? void 0 : (_vm$incomesCopy$idx = _vm$incomesCopy[idx]) === null || _vm$incomesCopy$idx === void 0 ? void 0 : _vm$incomesCopy$idx.income, idx);
              },
              "blur": function blur($event) {
                var _vm$incomesCopy2, _vm$incomesCopy2$idx;

                return _vm.inputTextType($event, (_vm$incomesCopy2 = _vm.incomesCopy) === null || _vm$incomesCopy2 === void 0 ? void 0 : (_vm$incomesCopy2$idx = _vm$incomesCopy2[idx]) === null || _vm$incomesCopy2$idx === void 0 ? void 0 : _vm$incomesCopy2$idx.income, idx);
              }
            },
            model: {
              value: _vm.incomesCopy[idx].income,
              callback: function callback($$v) {
                _vm.$set(_vm.incomesCopy[idx], "income", $$v);
              },
              expression: "incomesCopy[idx].income"
            }
          })], 1), _c('td', [_c('v-select', {
            attrs: {
              "dense": "",
              "items": _vm.incomeFreqOptions,
              "label": "Income Frequency",
              "disabled": _vm.FIELDS_DISABLED
            },
            model: {
              value: employeeIncome.income_freq,
              callback: function callback($$v) {
                _vm.$set(employeeIncome, "income_freq", $$v);
              },
              expression: "employeeIncome.income_freq"
            }
          })], 1), _c('td', [_c('v-icon', {
            attrs: {
              "disabled": _vm.FIELDS_DISABLED
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.removeEmployeeIncome(idx);
              }
            }
          }, [_vm._v(" mdi-delete ")])], 1)]);
        }), 0) : _vm._e()];
      },
      proxy: true
    }])
  }), _c('v-btn', {
    attrs: {
      "color": "primary",
      "disabled": _vm.FIELDS_DISABLED
    },
    on: {
      "click": _vm.addEmployeeIncome
    }
  }, [_vm._v(" Add new ")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }