var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('td', [_c('v-autocomplete', {
    attrs: {
      "no-filter": "",
      "dense": "",
      "autocomplete": "nofill",
      "items": _vm.addressOptions,
      "search-input": _vm.rentalIncomeAddress,
      "item-text": "address",
      "label": "Address",
      "hide-no-data": "",
      "disabled": _vm.FIELDS_DISABLED
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.rentalIncomeAddress = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.rentalIncomeAddress = $event;
      }
    },
    model: {
      value: _vm.rentalIncome.address,
      callback: function callback($$v) {
        _vm.$set(_vm.rentalIncome, "address", $$v);
      },
      expression: "rentalIncome.address"
    }
  })], 1), _c('td', [_c('v-combobox', {
    staticClass: "pa-5",
    attrs: {
      "items": _vm.typeOptions,
      "label": "Type",
      "dense": "",
      "disabled": _vm.FIELDS_DISABLED
    },
    model: {
      value: _vm.rentalIncome.type,
      callback: function callback($$v) {
        _vm.$set(_vm.rentalIncome, "type", $$v);
      },
      expression: "rentalIncome.type"
    }
  })], 1), _c('td', [_c('v-text-field', {
    attrs: {
      "dense": "",
      "type": "text",
      "placeholder": "Income, $",
      "label": "Income, $",
      "disabled": _vm.FIELDS_DISABLED
    },
    on: {
      "focus": _vm.inputNumberType,
      "blur": _vm.inputTextType
    },
    model: {
      value: _vm.rentalIncomeIncome,
      callback: function callback($$v) {
        _vm.rentalIncomeIncome = $$v;
      },
      expression: "rentalIncomeIncome"
    }
  })], 1), _c('td', [_c('v-select', {
    attrs: {
      "dense": "",
      "items": _vm.incomeFreqOptions,
      "label": "Income Frequency",
      "disabled": _vm.FIELDS_DISABLED
    },
    model: {
      value: _vm.rentalIncome.income_freq,
      callback: function callback($$v) {
        _vm.$set(_vm.rentalIncome, "income_freq", $$v);
      },
      expression: "rentalIncome.income_freq"
    }
  })], 1), _c('td', [_vm.userRole !== 'client' ? _c('v-text-field', {
    attrs: {
      "dense": "",
      "type": "number",
      "placeholder": "Shading, %",
      "label": "Shading, %",
      "disabled": _vm.FIELDS_DISABLED
    },
    on: {
      "blur": _vm.discountValueChange
    },
    model: {
      value: _vm.rentalIncomeDiscount,
      callback: function callback($$v) {
        _vm.rentalIncomeDiscount = $$v;
      },
      expression: "rentalIncomeDiscount"
    }
  }) : _vm._e()], 1), _c('td', [_c('v-icon', {
    attrs: {
      "disabled": _vm.FIELDS_DISABLED
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.removeRentalIncome.apply(null, arguments);
      }
    }
  }, [_vm._v(" mdi-delete ")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }